<template>
  <div class="potential contractDetail">
    <div ref="navBar">
      <rxNavBar title="变更协议详情"></rxNavBar>
    </div>
<!--    <p class="application_time">-->
<!--      签约日期：-->
<!--      <span>{{contractInfo.signTime ? contractInfo.signTime:'暂无'}}</span>-->
<!--    </p>-->
    <div class="panel withoutMargin">
      <div class="state" :class="colorMap[changeContractInfo.status]">
        {{changeContractInfo.statusStr}}
      </div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{contractInfo.houseAddress}}</p>
      </div>
      <van-row class="info">
        <van-col span="12">
          <p>
            {{changeContractInfo.contractType=='1'?'业主':'租客'}}：
            <span>{{changeContractInfo.contractType=='1'?contractInfo.ownerName:contractInfo.renterName}}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            电话：
            <span>{{changeContractInfo.contractType=='1'?contractInfo.ownerMobile?contractInfo.ownerMobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2'):'':contractInfo.renterMobile?contractInfo.renterMobile.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2'):''}}</span>
          </p>
        </van-col>
      </van-row>
      <div class="info">
        <p>
					<span>{{changeContractInfo.contractType=='1'?contractInfo.contractBeginTime:contractInfo.renterContractBeginTime}}
						至
						{{changeContractInfo.contractType=='1'?contractInfo.contractEndTime:contractInfo.renterContractEndTime}}
					</span>
        </p>
        <p>
          <span>{{changeContractInfo.contractType=='1'?contractInfo.rentMoney:contractInfo.renterRentMoney}}元/月</span>
          <span> {{changeContractInfo.contractType=='1'?contractInfo.payTypeName:contractInfo.renterPayTypeName}}</span>
          <span v-if="contractInfo.furtherAdvancedDays=='15'"> 再提前{{contractInfo.furtherAdvancedDays}}天</span>

        </p>
        <p>
          <van-tag color="rgb(250,223,209)" text-color="rgba(255, 93, 59, 1)" style="marginRight:8px">{{changeContractInfo.contractStatusStr}}</van-tag>
<!--                    <van-tag color="rgb(219,234,252)" text-color="rgba(86, 155, 236, 1)">{{contractInfo.contractStatusStr}}</van-tag>-->
          <van-tag color="rgb(219,234,252)" v-if="contractInfo.contractType == '1'" text-color="rgba(86, 155, 236, 1)">{{contractInfo.signType=='1'?'线上签约':'线下签约'}}</van-tag>
        </p>
      </div>
    </div>

    <div class="panel withoutMargin">
      <van-row class="info">
        <van-col span="12">
          <p>
            变更原因：
            <span>{{changeContractInfo.reasonDictionaryTitle}}</span>
          </p>
        </van-col>
        <van-col span="12">
          <p>
            变更人：
            <span>{{changeContractInfo.addStaffName}}</span>
          </p>
        </van-col>
        <van-col span="24">
          <p>
            变更时间：
            <span>{{changeContractInfo.addTime}}</span>
          </p>
        </van-col>
        <van-col span="24">
          <p>
            变更协议：
            <span>{{changeContractInfo.content}}</span>
          </p>
        </van-col>
      </van-row>
    </div>


    <div class="bottom-fixed" v-if="changeContractInfo.electricContractPath && changeContractInfo.status==='5'">
      <div class="electric-contract" @click="openElectricChangeContract">
        <img class="electric-contract-photo" src="../../../assets/images/electric-contract.png">
        <div>
          电子变更协议
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {findOwnerContractInfo, getPreviewURL, queryContractEditDetail} from "../../../getData/getData";
import {responseUtil,getStaffId} from "../../../libs/rongxunUtil";
import {Col, Row, Tag} from "vant";

export default {
  name: "changeContractDetail",
  components:{
    rxNavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tag.name]: Tag,
  },
  data(){
    return{
      item:{},
      contractInfo:{},
      changeContractInfo:{},
      colorMap:{
        //1待审核  2驳回  3已作废
        //4待签字  5已签字  6已处理
        '1': 'stateBlue',
        '2': 'stateRed',
        '4': 'stateOrange',
        '5': 'stateGreen',
        '6': 'stateGreen',
      }
    }
  },
  mounted(){
    this.item = this.$route.query
    this.initcontractInfo()
    this.queryContractEditDetail()
  },
  methods:{
    initcontractInfo() {
      let that = this
      let initData = {}
      //let id = that.$route.params.contractId
      initData.ownerContractId = that.item.contract_id
      findOwnerContractInfo(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.contractInfo = response.data.data.data

        })
      })
    },
    queryContractEditDetail(){
      let that = this
      let initData = {}
      initData.contractEditId = that.item.contractEditId
      queryContractEditDetail(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.changeContractInfo = response.data.data.data[0]
        })
      })
    },

    openElectricChangeContract(){
      let that = this
      let initData = {}
      initData.contractEditId = that.item.contractEditId
      getPreviewURL(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.code == 0) {
            let electronicContractUrl = response.data.data.path;
            if(response.data.data.path.slice(-3)!='pdf'){
              that.$router.push({
                name:'electronicContract',
                query:{
                  type:'ssqian',
                  title:'电子合同',
                  electronicContractUrl: electronicContractUrl,
                }
              });
            }else{
              that.$router.push({
                name:'electronicContract',
                query:{
                  type:'fxqian',
                  title:'电子合同',
                  electronicContractUrl: electronicContractUrl,
                }
              });
            }
          }
        })
      })
    }
  },
}
</script>

<style scoped lang="less">
@redius: 8px;
@spanColor: rgba(102, 102, 102, 1);
.potential {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
}
.application_time {
  text-align: right;
  width: 92%;
  font-size: 12px;
  color: rgb(136, 136, 136);
  padding: 10px 0 5px;
  margin: 0px;
}
.panel {
  /*width: 345px;*/
  width: 92%;
  margin: 15px auto;
  background-color: rgb(255, 255, 255);
  border-radius: @redius;
  position: relative;
  font-size: 15px;
  text-align: left;
  overflow: hidden;
  padding: 15px;
  box-sizing: border-box;

  &.withoutMargin {
    margin: 10px auto;
  }

  p {
    margin: 6px 0;
    font-weight: bolder;

    span {
      font-weight: normal;
      color: rgb(151, 151, 151);
    }
  }

  .state {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    font-weight: bold;
    height: 31px;
    line-height: 33px;
    width: 130px;
    display: block;
    background-color: rgb(163, 153, 153);
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
    transform: scale(0.5);
    transform-origin: 0% 0%;
    font-family: sans-serif;
    padding: 0 10px;


    &.stateGreen {
      background-image: linear-gradient(to right, #66CB94, #28D3B0);
    }

    &.stateRed {
      background-image: linear-gradient(to right, #E02020, #FF431B);
    }

    &.stateGray {
      background-image: linear-gradient(to right, #999999, #999999);
    }

    &.stateOrange {
      background-image: linear-gradient(to right, #FFC274, #FF5D3B);
    }

    &.stateBlue {
      background-image: linear-gradient(to right, #7FCAFF, #3091FF);
    }
  }

  .title {
    font-weight: bolder;
    color: rgb(0, 0, 0);
    font-size: 15px;
    /*padding: 20px 15px 15px;*/
    border-bottom: 1px solid rgb(250, 250, 250);

    span {
      font-weight: normal;
      color: rgba(153, 153, 153);
    }
  }

  .info {
    /*padding: 15px;*/
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(250, 250, 250);

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    .tenant {
      font-size: 18px;
      display: flex;
      align-items: center;
      .male {
        font-size: 12px;
        color: rgba(86, 155, 236, 1);
        margin-left: 10px;
      }

      .female {
        font-size: 12px;
        color: rgba(255, 140, 219, 1);
        margin-left: 10px;
      }
    }

    .DO_link {
      float: right;
      color: rgba(86, 155, 236, 1);
      cursor: pointer;
      font-size: 12px;
    }
  }

  &.refund {
    p {
      line-height: 24px;
      padding: 0;
      margin: 0;
    }
  }
}

.bottom-fixed {
  position: fixed;
  border-top: 1px solid rgb(243, 243, 243);
  width: 100%;
  height: 1.86667rem;
  bottom: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
}
//电子合同
.electric-contract {
  display: flex;
  flex-direction: column;
  align-items: center;
  //float: left;
  //height: 60px;
  //width: 70px;
  //margin-top: 0.25rem;
  //margin-left: 35px;
  //font-size: 14px;
}

//电子合同图片
.electric-contract-photo {
  //margin-top: 5px;
  //margin-left: 16px;
}
</style>